import React, { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// router
import { useParams } from "react-router";
// axios
import EnhancedAxios from "../../api/EnhancedAxios";
// mui
import { Box, Stack, Divider, Rating, Typography, Button } from "@mui/material";
import { RingsLoader } from "../../components";
// sections
import { DriverTripsDetails } from "../../sections";
import { warningAlert } from "../../utils/alerts";

const DriverInfo = ({ driver, setRerender }) => {
    console.log("driver info", driver);
    const [openDialog, setOpenDialog] = useState(false);
    const handleCloseDialog = () => setOpenDialog(false);
    const handleOpenDialog = () => {
        if (!driver?.driver_trx?.id)
            warningAlert("هذا السائق ليس لديه اى رحلات سابقة");
        else setOpenDialog(true);
    };
    return (
        <Stack>
            {/* rating row  */}
            <Stack
                direction="row"
                justifyContent="space-between"
                padding="20px 0"
            >
                <Typography variant="subtitle2">التقييم</Typography>
                <Rating
                    name="read-only"
                    value={driver.avg_rate_as_driver ?? 0}
                    precision={0.5}
                    readOnly
                />
            </Stack>
            <Divider flexItem />
            {/* row of credit info  */}
            <Box
                display="flex"
                gap="20px"
                flexWrap="wrap"
                component={Button}
                onClick={handleOpenDialog}
            >
                <Stack
                    alignItems="center"
                    gap="10px"
                    flex="1"
                    width="200px"
                    justifyContent="space-between"
                    minHeight="84px"
                >
                    <Typography variant="subtitle2">الرصيد المبدئي</Typography>
                    <Typography variant="h6">
                        {driver?.driver_trx?.starting_charge || 0}
                    </Typography>
                </Stack>
                <Divider flexItem orientation="vertical" />
                <Stack
                    alignItems="center"
                    gap="10px"
                    flex="1"
                    width="200px"
                    justifyContent="space-between"
                    minHeight="84px"
                >
                    <Typography variant="subtitle2">الرصيد الحالى</Typography>
                    <Typography variant="h6">
                        {driver?.driver_trx?.credit || 0}
                    </Typography>
                </Stack>
                <Divider flexItem orientation="vertical" />
                <Stack
                    alignItems="center"
                    gap="10px"
                    flex="1"
                    width="200px"
                    justifyContent="space-between"
                    minHeight="84px"
                >
                    <Typography variant="subtitle2" textAlign={"center"}>
                        اجمالى قيمة طلبات السائق النقدية
                    </Typography>
                    <Typography variant="h6">
                        {driver?.driver_trx?.total_cash || 0}
                    </Typography>
                </Stack>
                <Divider flexItem orientation="vertical" />
                <Stack
                    alignItems="center"
                    gap="10px"
                    flex="1"
                    width="200px"
                    justifyContent="space-between"
                    minHeight="84px"
                >
                    <Typography variant="subtitle2" textAlign={"center"}>
                        اجمالى قيمة طلبات السائق الرقمية
                    </Typography>
                    <Typography variant="h6">
                        {driver?.driver_trx?.total_digital || 0}
                    </Typography>
                </Stack>
                <Divider flexItem orientation="vertical" />
                <Stack
                    alignItems="center"
                    gap="10px"
                    flex="1"
                    width="200px"
                    justifyContent="space-between"
                    minHeight="84px"
                >
                    <Typography variant="subtitle2">
                        اجمال ارباح السائق
                    </Typography>
                    <Typography variant="h6">
                        {driver?.driver_trx?.profit || 0}
                    </Typography>
                </Stack>
            </Box>
            {/* dialog of trips */}
            {driver?.driver_trx?.id ? (
                <DriverTripsDetails
                    open={openDialog}
                    handleCloseDialog={handleCloseDialog}
                    driverTrxId={driver?.driver_trx?.id}
                    setRerender={setRerender}
                />
            ) : (
                <></>
            )}
        </Stack>
    );
};

export default DriverInfo;
