import moment from "moment";
import { LinkButton } from "../../components";
import { translateToArabic } from "../translate/englishToArabic";
export const driverTransactionsCols = [
    {
        field: "name",
        headerName: "اسم السائق",
        width: "150",
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.driver?.name ?? "غير معروف",
    },
    {
        field: "starting_charge",
        headerName: "بداية الشحنة",
        width: "100",
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.starting_charge ?? "غير معروف",
    },
    // {
    //     field: "details",
    //     headerName: "تفاصيل بداية الشحنة",
    //     width: "260",
    //     sortable: false,
    //     filterable: false,
    //     valueGetter: (params) =>
    //         params.row?.type ? params?.row?.details : "لا يوجد",
    // },
    // {
    //     field: "modified_charge",
    //     headerName: "الرصيد المعدل",
    //     width: "100",
    //     sortable: false,
    //     filterable: false,
    //     valueGetter: (params) => params.row?.modified_charge ?? "لا يوجد",
    // },
    {
        field: "notes",
        headerName: "ملاحظات",
        width: "530",
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.details ?? "لا يوجد",
    },
    {
        field: "created_at",
        headerName: "التاريخ",
        width: "150",
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.created_at
                ? moment(params.row.created_at).format("YYYY/MM/DD hh:mm")
                : "غير معروف",
    },
    {
        field: "editTransaction",
        headerName: "تفاصيل العملية",
        width: "120",
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <LinkButton
                text="مشاهدة"
                to={`/drivers-cash/${params.row?.id}`}
                size="small"
                variant="outlined"
            />
        ),
    },
];
