import { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// react router
import { useParams } from "react-router";
//axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// mui
import { Stack, Box, Typography, TextField, Divider } from "@mui/material";
// components
import {
    PageTitle,
    RingsLoader,
    SearchBox,
    SortBox,
} from "../../../components";
import { TableGrid } from "../../../sections";
// cols
import { driverTransactionsCols } from "../../../utils/gridCols/driverTransactionsCols";
// alerts
import { errorAlert } from "../../../utils/alerts";
import { translateToArabic } from "../../../utils/translate/englishToArabic";

const DriverCash = () => {
    const { trxId } = useParams();
    const userToken = useSelector((state) => state.userToken);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(12);
    const [totalItems, setTotalItems] = useState(20);
    const [searchValue, setSearchValue] = useState("");
    const [sortValue, setSortValue] = useState("-created_at");
    const [filterValues, setFilterValues] = useState({
        city_id: "",
        status: "is_disabled:eq:false",
        paymentMethod: "",
    });
    // ---------------------------- get_all_transactions ----------------------------
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(`/admin/driver-trx/${trxId}`)
            .then((res) => {
                setData(res.data);
                setLoading(false);
            })
            .catch((error) => errorAlert(error));
    }, [currentPage, itemsPerPage, searchValue, sortValue, filterValues]);
    // ----------------------------- components_ui -------------------------
    if (loading) return <RingsLoader />;
    return (
        <Stack gap="20px">
            <PageTitle title="تفاصيل المعاملة" />
            <Stack
                direction="row"
                gap="16px"
                flexWrap="wrap"
                justifyContent={"center"}
                sx={{
                    "& .MuiInputBase-root": { minWidth: "290px", flex: "1" },
                }}
            >
                <TextField
                    name="name"
                    label="اسم السائق"
                    value={data?.driver?.name}
                    inputProps={{ readOnly: true }}
                />
                <TextField
                    name="phone"
                    label="هاتف السائق"
                    value={data?.driver?.phone}
                    inputProps={{ readOnly: true }}
                />
                <TextField
                    name="starting_charge"
                    label="بداية الشحنة"
                    value={data?.starting_charge}
                    inputProps={{ readOnly: true }}
                />
            </Stack>
            <Stack>
                {loading ? (
                    <RingsLoader />
                ) : data.trips.length === 0 && searchValue === "" ? (
                    <Typography variant="body1">
                        لا يوجد عناصر لعرضها.
                    </Typography>
                ) : data.trips.length === 0 && searchValue !== "" ? (
                    <Typography variant="body1">
                        لا يوجد عناصر لعرضها خاصة نتيجة البحث.
                    </Typography>
                ) : (
                    data?.trips.map((trip) => <TripRow trip={trip} />)
                )}
            </Stack>
        </Stack>
    );
};

export default DriverCash;
const TripRow = ({ trip }) => {
    return (
        <>
            <Box display="flex" justifyContent="flex-start">
                <Stack
                    alignItems="center"
                    padding="20px"
                    flex="1"
                    minWidth="120px"
                >
                    <Typography variant="body2">قيمة الرحلة</Typography>
                    <Typography variant="h6">{trip.total_cost}</Typography>
                </Stack>
                <Divider flexItem orientation="vertical" />
                <Stack
                    alignItems="center"
                    padding="20px"
                    flex="1"
                    minWidth="120px"
                >
                    <Typography variant="body2">نسبة الربح</Typography>
                    <Typography variant="h6">{trip.app_profit}</Typography>
                </Stack>
                <Divider flexItem orientation="vertical" />
                <Stack
                    alignItems="center"
                    padding="20px"
                    flex="1"
                    minWidth="120px"
                >
                    <Typography variant="body2">طريقة الدفع</Typography>
                    <Typography variant="h6">
                        {trip.payment_method.name}
                    </Typography>
                </Stack>
                <Divider flexItem orientation="vertical" />
                <Stack
                    alignItems="center"
                    padding="20px"
                    flex="1"
                    minWidth="120px"
                >
                    <Typography variant="body2">الحالة</Typography>
                    <Typography variant="h6">
                        {translateToArabic(trip.status)}
                    </Typography>
                </Stack>
            </Box>
        </>
    );
};
