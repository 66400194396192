import React, { useState, useEffect } from "react";
// react redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../api/EnhancedAxios";
// mui
import {
    Dialog,
    DialogTitle,
    Box,
    Stack,
    Button,
    Typography,
    Divider,
} from "@mui/material";
// components
import { RingsLoader } from "../../components";
// alerts
import {
    askForConfirmationAlert,
    successAlert,
    errorAlert,
} from "../../utils/alerts";
import { translateToArabic } from "../../utils/translate/englishToArabic";
const DriverTripsDetails = ({
    open,
    handleCloseDialog,
    driverTrxId,
    setRerender,
}) => {
    const userToken = useSelector((state) => state.userToken);
    const [trips, setTrips] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(`/admin/driver-trx/${driverTrxId}`)
            .then((res) => {
                setTrips(res.data.trips);
                setLoading(false);
            });
    }, [driverTrxId]);

    return (
        <Dialog
            open={open}
            onClose={handleCloseDialog}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle>دليل رحلات السائق</DialogTitle>
            {loading ? (
                <RingsLoader />
            ) : (
                <Stack minWidth="360px" overflow="auto">
                    {trips.length ? (
                        trips.map((trip, index) => (
                            <>
                                <TripRow
                                    trip={trip}
                                    driverTrxId={driverTrxId}
                                    setRerender={setRerender}
                                    handleCloseDialog={handleCloseDialog}
                                />
                                {index < trips.length - 1 ? <Divider /> : <></>}
                            </>
                        ))
                    ) : (
                        <p className="text-center">لا يوجد رحلات لهذا السائق</p>
                    )}
                </Stack>
            )}
        </Dialog>
    );
};

export default DriverTripsDetails;

const TripRow = ({ trip, driverTrxId, setRerender, handleCloseDialog }) => {
    const userToken = useSelector((state) => state.userToken);
    const [hideRow, setHideRow] = useState(false);
    // ---------------------- delteing_item ----------------------
    const deletingProcess = () => {
        EnhancedAxios(null, userToken)
            .delete(`/admin/driver-trx/${driverTrxId}/remove-trip`, {
                data: { trip_id: trip.id },
            })
            .then((res) => {
                successAlert("تم حذف العنصر");
                // setDataChanged((prev) => !prev);
                setHideRow(true);
                setRerender(Math.random());
                handleCloseDialog();
            })
            .catch((error) => errorAlert(error));
    };
    const deleteItem = () => {
        askForConfirmationAlert(
            "هل انت متاكد انك تريد حذف هذا العنصر",
            deletingProcess
        );
    };
    if (hideRow) return <></>;
    return (
        <Box display="flex" justifyContent="center" gap="10px">
            {/* <Stack alignItems="center" padding="20px" flex="1">
                <Typography variant="body2">رقم الرحلة</Typography>
                <Typography variant="h6">{trip.friendly_id}</Typography>
            </Stack>
            <Divider flexItem orientation="vertical" /> */}
            <Stack alignItems="center" padding="20px" flex="1">
                <Typography variant="body2">قيمة الرحلة</Typography>
                <Typography variant="h6">{trip.total_cost}</Typography>
            </Stack>
            <Divider flexItem orientation="vertical" />
            <Stack alignItems="center" padding="20px" flex="1">
                <Typography variant="body2">نسبة الربح</Typography>
                <Typography variant="h6">{trip.app_profit}</Typography>
            </Stack>
            <Divider flexItem orientation="vertical" />
            <Stack alignItems="center" padding="20px" flex="1">
                <Typography variant="body2">طريقة الدفع</Typography>
                <Typography variant="h6">{trip.payment_method.name}</Typography>
            </Stack>
            <Divider flexItem orientation="vertical" />
            <Stack alignItems="center" padding="20px" flex="1">
                <Typography variant="body2">الحالة</Typography>
                <Typography variant="h6">
                    {translateToArabic(trip.status)}
                </Typography>
            </Stack>
            <Divider flexItem orientation="vertical" />
            <Stack alignItems="center" padding="20px" flex="1">
                <Button variant="outlined" color="error" onClick={deleteItem}>
                    حذف
                </Button>
            </Stack>
        </Box>
    );
};
