import { LinkButton, ToggleStatus } from "../../components";
import { Link } from "react-router-dom";
import { translateToArabic } from "../translate/englishToArabic";
import moment from "moment";

export const driversCols = [
    {
        field: "name",
        headerName: "اسم المستخدم",
        width: "180",
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.name ?? "غير معروف",
    },
    {
        field: "phone",
        headerName: "رقم الهاتف",
        width: "180",
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.phone ?? "لايوجد",
    },
    {
        field: "gender",
        headerName: "النوع",
        width: "120",
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.gender
                ? translateToArabic(params.row.gender)
                : "لا يوجد",
    },
    {
        field: "created_at",
        headerName: "تاريخ الانشاء",
        width: "180",
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.created_at
                ? moment(params.row.created_at).format(`YYYY/MM/DD-hh:mm`)
                : "لا يوجد",
    },
    {
        field: "toggleStatus",
        headerName: "الحالة",
        width: "70",
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <ToggleStatus
                id={params.row.id}
                is_disabled={params.row.is_disabled}
                putApiUrl={`admin/users/${params.row.id}`}
            />
        ),
    },
    {
        field: "showDetails",
        headerName: "مشاهدة",
        width: "100",
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <LinkButton
                text="مشاهدة"
                to={`/edit-driver/${params.row?.id}`}
                size="small"
                variant="outlined"
            />
        ),
    },
    {
        field: "showCashList",
        headerName: "مشاهدة السجل المالي",
        width: "150",
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <LinkButton
                text="السجل المالي"
                to={`/drivers-cash?driver_id=${params.row?.id}`}
                size="small"
                variant="outlined"
            />
        ),
    },
];
