import { useEffect, useState } from "react";
// router
import { useParams } from "react-router";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../api/EnhancedAxios";
// formik
import { useFormik } from "formik";
// mui
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Box,
    Stack,
    Typography,
    Divider,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
// componetns
import { RingsLoader, SubmitButton } from "../../components";
// alerts
import { errorAlert, successAlert } from "../../utils/alerts";
import { MdAccountBalanceWallet } from "react-icons/md";

const DriverChangeCash = ({
    openChargeCashForm,
    setOpenChargeCashForm,
    driver,
    setRerender,
}) => {
    const userToken = useSelector((state) => state.userToken);
    const [loading, setLoading] = useState(true);
    const [driverTrxId, setDriverTrxId] = useState("");
    // ---------- charge_cash_form_toggelers_functions ----------
    const handleOpenDialog = () => {
        setOpenChargeCashForm(true);
    };
    const handleCloseDialog = () => {
        setOpenChargeCashForm(false);
    };
    // ---------- submit_form ----------
    const onSubmit = (values) => {
        setSubmitting(false);
        EnhancedAxios("application/json", userToken)
            .put(`/admin/driver-trx/${driverTrxId}`, values)
            .then((res) => {
                successAlert(`تم تغيير رصيد السائق`);
                setSubmitting(false);
                resetForm();
                setRerender(Math.random());
                handleCloseDialog();
            })
            .catch((error) => {
                errorAlert(error);
                setSubmitting(false);
            });
    };
    // ---------- form_configration ----------
    const {
        values,
        handleChange,
        handleSubmit,
        handleBlur,
        isSubmitting,
        setSubmitting,
        resetForm,
    } = useFormik({
        initialValues: {
            details: "",
            // modified_details: "",
            starting_charge: "",
            // modified_charge: "",
            is_locked: false,
            driver: {
                id: driver?.id,
            },
        },
        onSubmit,
    });
    // ---------- page_ui ----------
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get(`/admin/driver-trx/create-or-get/${driver.id}`)
            .then((res) => {
                setDriverTrxId(res?.data?.id);
                setLoading(false);
            })
            .catch((err) => {
                errorAlert(err);
            });
    }, []);
    return (
        <Dialog
            open={openChargeCashForm}
            onClose={handleCloseDialog}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle>عملية تعديل رصيد للسائق </DialogTitle>
            {loading ? (
                <RingsLoader />
            ) : (
                <>
                    <DialogContent>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            textAlign="center"
                            gap="20px"
                            flexWrap="wrap"
                        >
                            <Stack>
                                <Typography variant="subtitle2">
                                    الرصيد المبدئي
                                </Typography>
                                <Typography variant="h6">
                                    {driver?.driver_trx?.starting_charge || 0}
                                </Typography>
                            </Stack>
                            <Divider flexItem orientation="vertical" />
                            <Stack>
                                <Typography variant="subtitle2">
                                    الرصيد الحالي
                                </Typography>
                                <Typography variant="h6">
                                    {driver?.driver_trx?.credit || 0}
                                </Typography>
                            </Stack>
                            <Divider flexItem orientation="vertical" />
                            <Stack>
                                <Typography variant="subtitle2">
                                    قيمة طلبات السائق النقدية
                                </Typography>
                                <Typography variant="h6">
                                    {driver?.driver_trx?.total_cash || 0}
                                </Typography>
                            </Stack>
                            <Divider flexItem orientation="vertical" />
                            <Stack>
                                <Typography variant="subtitle2">
                                    قيمة طلبات السائق الرقمية
                                </Typography>
                                <Typography variant="h6">
                                    {driver?.driver_trx?.total_digital || 0}
                                </Typography>
                            </Stack>
                            <Divider flexItem orientation="vertical" />
                            <Stack>
                                <Typography variant="subtitle2">
                                    أرباح السائق
                                </Typography>
                                <Typography variant="h6">
                                    {driver?.driver_trx?.profit}
                                </Typography>
                            </Stack>
                        </Box>
                    </DialogContent>
                    <Divider />
                    <DialogContent>
                        <Stack
                            component="form"
                            onSubmit={handleSubmit}
                            gap="20px"
                        >
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                gap="20px"
                                flexWrap="wrap"
                                py={1}
                                width="100%"
                                sx={{
                                    flexDirection: { xs: "column", sm: "row" },
                                }}
                            >
                                <Typography variant="subtitle2">
                                    شحن رصيد
                                </Typography>
                                <TextField
                                    autoFocus
                                    name="starting_charge"
                                    label="قيمة المبلغ"
                                    value={values.starting_charge}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="number"
                                />
                                <TextField
                                    name="details"
                                    label="ملاحظات"
                                    value={values.details}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    sx={{ flex: "1" }}
                                />
                            </Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="is_locked"
                                        checked={values.is_locked}
                                        onChange={handleChange}
                                    />
                                }
                                label="تصفية المعاملات"
                            />
                            <SubmitButton
                                text="تأكيد"
                                disabled={isSubmitting}
                            />
                        </Stack>
                    </DialogContent>
                </>
            )}
        </Dialog>
    );
};

export default DriverChangeCash;
