import React, { useState, useEffect } from "react";
// react redux
import { useSelector } from "react-redux";
// mui
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    Stack,
    Typography,
    Divider,
    Button,
} from "@mui/material";
// axios
import EnhancedAxios from "../../api/EnhancedAxios";
import {
    askForConfirmationAlert,
    errorAlert,
    successAlert,
} from "../../utils/alerts";
import { translateToArabic } from "../../utils/translate/englishToArabic";
const AddTripForDriver = ({ open, setOpen, driverId }) => {
    const userToken = useSelector((state) => state.userToken);
    const [driverTrxId, setDriverTrxId] = useState("");
    const [trips, setTrips] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        // EnhancedAxios(null, userToken)
        //     .get(`/admin/driver-trx/assignable-trips`)
        //     .then((res) => {
        //         setTrips(res.data);
        //         setLoading(false);
        //     });
        Promise.allSettled([
            EnhancedAxios(null, userToken).get(
                `/admin/driver-trx/assignable-trips`
            ),
            EnhancedAxios(null, userToken).get(
                `/admin/driver-trx/create-or-get/${driverId}`
            ),
        ])
            .then((responses) => {
                setTrips(responses[0].value.data);
                setDriverTrxId(responses[1].value.data.id);
            })
            .catch((error) => {
                errorAlert(error);
            });
    }, []);
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle>عملية رحلة الي السائق</DialogTitle>
            <DialogContent>
                {/* <Stack width="100%" minWidth="360px" overflow="auto"> */}
                {trips?.data?.map((trip, index) => (
                    <>
                        <TripRow trip={trip} driverTrxId={driverTrxId} />
                    </>
                ))}
                {/* </Stack> */}
            </DialogContent>
        </Dialog>
    );
};

export default AddTripForDriver;

const TripRow = ({ trip, driverTrxId }) => {
    console.log(trip);
    const userToken = useSelector((state) => state.userToken);
    const [hideRow, setHideRow] = useState(false);
    // ---------------------- delteing_item ----------------------
    const addTrip = () => {
        EnhancedAxios(null, userToken)
            .post(
                `/admin/driver-trx/${driverTrxId}/add-trip`,
                JSON.stringify({ trip_id: trip.id })
            )
            .then((res) => {
                successAlert("تم  اضافة الرحلة الي السائق");
                // setDataChanged((prev) => !prev);
                setHideRow(true);
            })
            .catch((error) => errorAlert(error));
    };

    if (hideRow || !driverTrxId) return <></>;
    return (
        <>
            <Box
                display="flex"
                justifyContent="flex-start"
                // gap="10px"
                // borderBottom="1px solid #ddd"
            >
                <Stack
                    alignItems="center"
                    padding="20px"
                    flex="1"
                    minWidth="220px"
                >
                    <Typography variant="body2">رقم الرحلة</Typography>
                    <Typography variant="h6">{trip.friendly_id}</Typography>
                </Stack>
                <Divider flexItem orientation="vertical" />
                <Stack
                    alignItems="center"
                    padding="20px"
                    flex="1"
                    minWidth="120px"
                >
                    <Typography variant="body2">قيمة الرحلة</Typography>
                    <Typography variant="h6">{trip.total_cost}</Typography>
                </Stack>
                <Divider flexItem orientation="vertical" />
                <Stack
                    alignItems="center"
                    padding="20px"
                    flex="1"
                    minWidth="120px"
                >
                    <Typography variant="body2">نسبة الربح</Typography>
                    <Typography variant="h6">{trip.app_profit}</Typography>
                </Stack>
                <Divider flexItem orientation="vertical" />
                <Stack
                    alignItems="center"
                    padding="20px"
                    flex="1"
                    minWidth="120px"
                >
                    <Typography variant="body2">طريقة الدفع</Typography>
                    <Typography variant="h6">
                        {trip.payment_method.name}
                    </Typography>
                </Stack>
                <Divider flexItem orientation="vertical" />
                <Stack
                    alignItems="center"
                    padding="20px"
                    flex="1"
                    minWidth="120px"
                >
                    <Typography variant="body2">الحالة</Typography>
                    <Typography variant="h6">
                        {translateToArabic(trip.status)}
                    </Typography>
                </Stack>
                <Divider flexItem orientation="vertical" />
                <Stack
                    alignItems="center"
                    padding="20px"
                    flex="1"
                    minWidth="120px"
                >
                    <Button variant="outlined" onClick={addTrip}>
                        اضافة
                    </Button>
                </Stack>
            </Box>
            <Divider />
        </>
    );
};
