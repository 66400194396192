import { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
//axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// mui
import { Stack, Box, Typography } from "@mui/material";
// components
import {
    PageTitle,
    RingsLoader,
    SearchBox,
    SortBox,
} from "../../../components";
import { TableGrid } from "../../../sections";
// cols
import { driverTransactionsCols } from "../../../utils/gridCols/driverTransactionsCols";
// alerts
import { errorAlert } from "../../../utils/alerts";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
// ----------------------------- page_functional_component -------------------------
const DriversCash = () => {
    const userToken = useSelector((state) => state.userToken);
    const [searchParams, setSearchParams] = useSearchParams();
    console.log("dirver id in drivers cash", searchParams.get("driver_id"));
    const [loading, setLoading] = useState(true);
    const [drivers, setDrivers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(12);
    const [totalItems, setTotalItems] = useState(20);
    const [searchValue, setSearchValue] = useState("");
    const [sortValue, setSortValue] = useState("-created_at");
    const [filterValues, setFilterValues] = useState({
        city_id: "",
        status: "is_disabled:eq:false",
        paymentMethod: "",
        driver_id: searchParams.get("driver_id")
            ? `driver_id:${searchParams.get("driver_id")}`
            : "",
    });
    // ---------------------------- get_all_transactions ----------------------------
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(
                `/admin/driver-trx?page=${currentPage}&paginate=${itemsPerPage}&sorts=${sortValue}&q=${searchValue}&filters=${filterValues.driver_id}`
            )
            .then((res) => {
                setDrivers(res.data.data);
                setTotalItems(res.data.meta.total);
                setLoading(false);
            })
            .catch((error) => errorAlert(error));
    }, [currentPage, itemsPerPage, searchValue, sortValue, filterValues]);
    // ----------------------------- components_ui -------------------------
    return (
        <Stack gap="20px">
            <PageTitle title="إدارة العمليات المالية للسائقين" />
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="20px"
                flexWrap="wrap"
            >
                <SearchBox
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                />
                <SortBox sortValue={sortValue} setSortValue={setSortValue} />
            </Box>
            <Stack>
                {loading ? (
                    <RingsLoader />
                ) : drivers.length === 0 && searchValue === "" ? (
                    <Typography variant="body1">
                        لا يوجد عناصر لعرضها.
                    </Typography>
                ) : drivers.length === 0 && searchValue !== "" ? (
                    <Typography variant="body1">
                        {" "}
                        لا يوجد عناصر لعرضها خاصة نتيجة البحث.
                    </Typography>
                ) : (
                    <TableGrid
                        rows={drivers}
                        cols={driverTransactionsCols}
                        itemsPerPage={itemsPerPage}
                        totalItems={totalItems}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                )}
            </Stack>
        </Stack>
    );
};

export default DriversCash;
